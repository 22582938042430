GraphQL ID: presetTraits
list:

  - name: Power Chrono IA GS
    profession: Chronomancer
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "10",
          "1",
          "40"
        ],
        "selectedTraits": [
          [
            686,
            713,
            681
          ],
          [
            701,
            708,
            692
          ],
          [
            1995,
            1978,
            1890
          ]
        ],
        "items": [
          {
            "fragility": {
              "amount": ""
            },
            "egotism": {
              "amount": ""
            },
            "vicious-expression": {
              "amount": ""
            },
            "empowered-illusions": {}
          },
          {
            "fencers-finesse": {
              "amount": "8.6"
            },
            "superiority-complex-base": {},
            "superiority-complex": {
              "amount": ""
            },
            "phantasmal-fury": {},
            "sharper-images-phantasm": {
              "amount": "4.18"
            },
            "sharper-images-clone": {
              "amount": "0.78"
            }
          },
          {
            "danger-time": {},
            "chronophantasma": {},
            "improved-alacrity": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Boon Power Chrono Spear
    profession: Chronomancer
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "10",
          "1",
          "40"
        ],
        "selectedTraits": [
          [
            682,
            713,
            681
          ],
          [
            701,
            708,
            692
          ],
          [
            1995,
            1978,
            0
          ]
        ],
        "items": [
          {
            "fragility": {
              "amount": ""
            },
            "egotism": {
              "amount": ""
            },
            "vicious-expression": {
              "amount": ""
            },
            "empowered-illusions": {}
          },
          {
            "fencers-finesse": {
              "amount": "6.8"
            },
            "superiority-complex-base": {},
            "superiority-complex": {
              "amount": ""
            },
            "phantasmal-fury": {},
            "sharper-images-phantasm": {
              "amount": "1.26"
            },
            "sharper-images-clone": {
              "amount": "0.51"
            }
          },
          {
            "danger-time": {},
            "improved-alacrity": {},
            "chronophantasma": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Boon Power Chrono GS
    profession: Chronomancer
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "10",
          "1",
          "40"
        ],
        "selectedTraits": [
          [
            686,
            713,
            681
          ],
          [
            701,
            708,
            692
          ],
          [
            1995,
            1978,
            2022
          ]
        ],
        "items": [
          {
            "fragility": {
              "amount": ""
            },
            "egotism": {
              "amount": ""
            },
            "vicious-expression": {
              "amount": ""
            },
            "empowered-illusions": {}
          },
          {
            "fencers-finesse": {
              "amount": "6.45"
            },
            "superiority-complex-base": {},
            "superiority-complex": {
              "amount": ""
            },
            "phantasmal-fury": {},
            "sharper-images-phantasm": {
              "amount": "2.01"
            },
            "sharper-images-clone": {
              "amount": "0.86"
            }
          },
          {
            "danger-time": {},
            "chronophantasma": {},
            "improved-alacrity": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Heal Chrono
    profession: Chronomancer
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "45",
          "23",
          "40"
        ],
        "selectedTraits": [
          [
            675,
            668,
            1687
          ],
          [
            738,
            740,
            1866
          ],
          [
            1987,
            1978,
            0
          ]
        ],
        "items": [
          {
            "illusionary-membrane": {
              "amount": ""
            },
            "chaotic-potency": {},
            "chaotic-persistence": {}
          },
          {
            "illusionary-inspiration": {}
          },
          {
            "danger-time": {},
            "improved-alacrity": {},
            "chronophantasma": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }


  - name: Axe Mirage
    profession: Mirage
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "45",
          "1",
          "59"
        ],
        "selectedTraits": [
          [
            670,
            669,
            671
          ],
          [
            700,
            1889,
            1950
          ],
          [
            2141,
            2098,
            2070
          ]
        ],
        "items": [
          {
            "illusionary-defense": {
              "amount": ""
            },
            "illusionary-membrane": {
              "amount": ""
            },
            "chaotic-potency": {},
            "chaotic-persistence": {}
          },
          {
            "fencers-finesse": {
              "amount": ""
            },
            "superiority-complex-base": {},
            "superiority-complex": {
              "amount": ""
            },
            "sharper-images-clone": {
              "amount": "3.53"
            },
            "sharper-images-phantasm": {
              "amount": "0.69"
            }
          },
          {
            "nomads-endurance": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-domination": {},
          "signet-of-midnight": {}
        }
      }

  - name: Alacrity Staff Mirage
    profession: Mirage
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "45",
          "1",
          "59"
        ],
        "selectedTraits": [
          [
            670,
            669,
            1687
          ],
          [
            701,
            1889,
            1950
          ],
          [
            2110,
            2174,
            2070
          ]
        ],
        "items": [
          {
            "illusionary-defense": {
              "amount": ""
            },
            "illusionary-membrane": {
              "amount": ""
            },
            "chaotic-potency": {},
            "chaotic-potency-2": {
              "amount": ""
            },
            "chaotic-persistence": {}
          },
          {
            "fencers-finesse": {
              "amount": ""
            },
            "superiority-complex-base": {},
            "superiority-complex": {
              "amount": ""
            },
            "sharper-images-clone": {
              "amount": "2.75"
            },
            "sharper-images-phantasm": {
              "amount": "0.32"
            },
            "phantasmal-fury": {}
          },
          {
            "nomads-endurance": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-domination": {},
          "signet-of-midnight": {}
        }
      }

  - name: Condi Staff / Axe Mirage
    profession: Mirage
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "45",
          "1",
          "59"
        ],
        "selectedTraits": [
          [
            670,
            669,
            1687
          ],
          [
            700,
            1889,
            1950
          ],
          [
            2110,
            2098,
            2070
          ]
        ],
        "items": [
          {
            "illusionary-defense": {
              "amount": ""
            },
            "illusionary-membrane": {
              "amount": ""
            },
            "chaotic-potency": {},
            "chaotic-potency-2": {
              "amount": ""
            },
            "chaotic-persistence": {}
          },
          {
            "fencers-finesse": {
              "amount": ""
            },
            "superiority-complex-base": {},
            "superiority-complex": {
              "amount": ""
            },
            "sharper-images-clone": {
              "amount": "3.95"
            },
            "sharper-images-phantasm": {
              "amount": "0.73"
            },
            "phantasmal-fury": {}
          },
          {
            "nomads-endurance": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-domination": {},
          "signet-of-midnight": {}
        }
      }

  - name: Boon Condi Chrono
    profession: Chronomancer
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "24",
          "1",
          "40"
        ],
        "selectedTraits": [
          [
            691,
            1690,
            753
          ],
          [
            700,
            1889,
            1950
          ],
          [
            1995,
            1978,
            0
          ]
        ],
        "items": [
          {
            "compounding-power": {
              "amount": "4.7"
            }
          },
          {
            "fencers-finesse": {
              "amount": ""
            },
            "superiority-complex-base": {},
            "superiority-complex": {
              "amount": ""
            },
            "sharper-images-clone": {
              "amount": "0.39"
            },
            "sharper-images-phantasm": {
              "amount": "1.09"
            }
          },
          {
            "danger-time": {},
            "improved-alacrity": {},
            "chronophantasma": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-domination": {},
          "signet-of-midnight": {}
        }
      }

  - name: Condi Virtuoso Dueling
    profession: Virtuoso
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "24",
          "1",
          "66"
        ],
        "selectedTraits": [
          [
            721,
            1690,
            733
          ],
          [
            701,
            708,
            692
          ],
          [
            2202,
            2205,
            2223
          ]
        ],
        "items": [
          {
            "compounding-power": {
              "amount": ""
            },
            "Phantasmal Force": {
              "amount": ""
            }
          },
          {
            "fencers-finesse": {
              "amount": "5.24"
            },
            "superiority-complex-base": {},
            "superiority-complex": {
              "amount": ""
            },
            "sharper-images-phantasm": {
              "amount": "1.8"
            },
            "phantasmal-fury-virtuoso": {}
          },
          {
            "mental-focus": {
              "amount": ""
            },
            "deadly-blades": {
              "amount": "100"
            },
            "sharpening-sorrow": {},
            "quiet-intensity": {},
            "quiet-intensity-fury": {},
            "bloodsong": {},
            "jagged-mind": {
              "amount": "6.04"
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-domination": {},
          "signet-of-midnight": {}
        }
      }

  - name: Condi Virtuoso Chaos
    profession: Virtuoso
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "24",
          "45",
          "66"
        ],
        "selectedTraits": [
          [
            721,
            1690,
            2035
          ],
          [
            670,
            669,
            671
          ],
          [
            2202,
            2207,
            2223
          ]
        ],
        "items": [
          {
            "compounding-power": {
              "amount": ""
            },
            "Phantasmal Force": {
              "amount": ""
            }
          },
          {
            "illusionary-defense": {
              "amount": ""
            },
            "illusionary-membrane": {
              "amount": ""
            },
            "chaotic-potency": {},
            "chaotic-persistence": {}
          },
          {
            "mental-focus": {
              "amount": ""
            },
            "deadly-blades": {
              "amount": "100"
            },
            "sharpening-sorrow": {},
            "quiet-intensity": {},
            "quiet-intensity-fury": {},
            "bloodsong": {},
            "jagged-mind": {
              "amount": "5.86"
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-domination": {},
          "signet-of-midnight": {}
        }
      }

  - name: Power Virtuoso Spear
    profession: Virtuoso
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "10",
          "1",
          "66"
        ],
        "selectedTraits": [
          [
            682,
            713,
            681
          ],
          [
            701,
            708,
            692
          ],
          [
            2208,
            2205,
            2223
          ]
        ],
        "items": [
          {
            "fragility": {
              "amount": ""
            },
            "egotism": {
              "amount": ""
            },
            "vicious-expression": {
              "amount": ""
            },
            "empowered-illusions": {}
          },
          {
            "fencers-finesse": {
              "amount": "5.47"
            },
            "superiority-complex-base": {},
            "superiority-complex": {
              "amount": ""
            },
            "sharper-images-phantasm": {
              "amount": ".85"
            },
            "phantasmal-fury-virtuoso": {}
          },
          {
            "mental-focus": {
              "amount": ""
            },
            "deadly-blades": {
              "amount": "98.7"
            },
            "sharpening-sorrow": {},
            "quiet-intensity": {},
            "quiet-intensity-fury": {},
            "bloodsong": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Virtuoso GS
    profession: Virtuoso
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "10",
          "1",
          "66"
        ],
        "selectedTraits": [
          [
            686,
            713,
            681
          ],
          [
            701,
            708,
            692
          ],
          [
            2208,
            2205,
            2223
          ]
        ],
        "items": [
          {
            "fragility": {
              "amount": ""
            },
            "egotism": {
              "amount": ""
            },
            "vicious-expression": {
              "amount": ""
            }
          },
          {
            "fencers-finesse": {
              "amount": "4.69"
            },
            "superiority-complex-base": {},
            "superiority-complex": {
              "amount": ""
            },
            "sharper-images-phantasm": {
              "amount": "1.7"
            },
            "phantasmal-fury-virtuoso": {}
          },
          {
            "mental-focus": {
              "amount": ""
            },
            "deadly-blades": {
              "amount": "90.8"
            },
            "sharpening-sorrow": {},
            "quiet-intensity": {},
            "quiet-intensity-fury": {},
            "bloodsong": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: DH Radiance
    profession: Dragonhunter
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "42",
          "27"
        ],
        "selectedTraits": [
          [
            574,
            565,
            1683
          ],
          [
            634,
            653,
            2017
          ],
          [
            0,
            1835,
            1955
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": ""
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "fiery-wrath": {},
            "symbolic-exposure": {},
            "zealous-blade-base": {},
            "zealous-blade-gs": {
              "amount": ""
            },
            "kindled-zeal": {},
            "symbolic-avenger": {}
          },
          {
            "defenders-dogma": {},
            "zealots-aggression": {},
            "pure-of-sight": {},
            "heavy-light-defiant": {},
            "heavy-light-defiant-disabled": {
              "amount": ""
            },
            "big-game-hunter": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "bane-signet": {
            "amount": ""
          }
        }
      }

  - name: DH Virtues
    profession: Dragonhunter
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "46",
          "42",
          "27"
        ],
        "selectedTraits": [
          [
            624,
            603,
            0
          ],
          [
            634,
            653,
            2017
          ],
          [
            1911,
            1835,
            1955
          ]
        ],
        "items": [
          {
            "inspired-virtue": {
              "amount": ""
            },
            "unscathed-contender-health": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": "67"
            },
            "power-of-the-virtuous": {}
          },
          {
            "fiery-wrath": {},
            "symbolic-exposure": {},
            "zealous-blade-base": {},
            "zealous-blade-gs": {
              "amount": ""
            },
            "kindled-zeal": {},
            "symbolic-avenger": {}
          },
          {
            "defenders-dogma": {},
            "zealots-aggression": {},
            "pure-of-sight": {},
            "heavy-light-defiant": {},
            "heavy-light-defiant-disabled": {
              "amount": ""
            },
            "big-game-hunter": {
              "amount": "89"
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "bane-signet": {
            "amount": ""
          }
        }
      }

  - name: Core Guardian
    profession: Guardian
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "42",
          "46"
        ],
        "selectedTraits": [
          [
            566,
            565,
            1683
          ],
          [
            634,
            653,
            2017
          ],
          [
            624,
            603,
            622
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": ""
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "fiery-wrath": {},
            "symbolic-exposure": {},
            "zealous-blade-base": {},
            "zealous-blade-gs": {
              "amount": ""
            },
            "kindled-zeal": {},
            "symbolic-avenger": {}
          },
          {
            "inspired-virtue": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": "80"
            },
            "power-of-the-virtuous": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "bane-signet": {
            "amount": ""
          }
        }
      }

  - name: Power Quickbrand
    profession: Firebrand
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "42",
          "62"
        ],
        "selectedTraits": [
          [
            574,
            565,
            1683
          ],
          [
            634,
            653,
            2017
          ],
          [
            2101,
            2076,
            2159
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": ""
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "fiery-wrath": {},
            "symbolic-exposure": {},
            "zealous-blade-base": {},
            "zealous-blade-gs": {
              "amount": ""
            },
            "kindled-zeal": {},
            "symbolic-avenger": {}
          },
          {
            "imbued-haste": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "bane-signet": {
            "amount": ""
          }
        }
      }

  - name: Condi Willbender
    profession: Willbender
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "46",
          "65"
        ],
        "selectedTraits": [
          [
            566,
            567,
            1686
          ],
          [
            617,
            603,
            622
          ],
          [
            2191,
            2197,
            2201
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": "100"
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "inspired-virtue": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": "95.4"
            },
            "power-of-the-virtuous": {}
          },
          {
            "searing-pact": {},
            "lethal-tempo": {
              "amount": ""
            },
            "tyrants-momentum": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-wrath": {
            "amount": ""
          }
        }
      }

  - name: Quickess Condi Firebrand P/T P/P
    profession: Firebrand
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "46",
          "62"
        ],
        "selectedTraits": [
          [
            566,
            567,
            1686
          ],
          [
            617,
            610,
            622
          ],
          [
            2101,
            2063,
            2179
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": "100"
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "inspired-virtue": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": ""
            },
            "power-of-the-virtuous": {}
          },
          {
            "imbued-haste": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi Firebrand Zeal
    profession: Firebrand
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "42",
          "62"
        ],
        "selectedTraits": [
          [
            566,
            567,
            1686
          ],
          [
            634,
            1556,
            2017
          ],
          [
            2075,
            2063,
            2179
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": "100"
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "fiery-wrath": {},
            "symbolic-exposure": {},
            "zealous-blade-base": {},
            "zealous-blade-gs": {
              "amount": ""
            },
            "kindled-zeal": {},
            "symbolic-avenger": {}
          },
          {
            "imbued-haste": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-wrath": {
            "amount": ""
          }
        }
      }

  - name: Condi Firebrand Virtues
    profession: Firebrand
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "46",
          "62"
        ],
        "selectedTraits": [
          [
            566,
            567,
            1686
          ],
          [
            617,
            587,
            622
          ],
          [
            2075,
            2063,
            2179
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": "100"
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "inspired-virtue": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": ""
            },
            "power-of-the-virtuous": {}
          },
          {
            "imbued-haste": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-wrath": {
            "amount": ""
          }
        }
      }

  - name: Hybrid FB Virtues
    profession: Firebrand
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "46",
          "62"
        ],
        "selectedTraits": [
          [
            566,
            567,
            1686
          ],
          [
            617,
            587,
            622
          ],
          [
            2101,
            2116,
            2159
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": "100"
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "inspired-virtue": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": ""
            },
            "power-of-the-virtuous": {}
          },
          {
            "imbued-haste": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Hybrid FB Honor
    profession: Firebrand
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "49",
          "62"
        ],
        "selectedTraits": [
          [
            566,
            567,
            1686
          ],
          [
            654,
            562,
            558
          ],
          [
            2075,
            2116,
            2179
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": "100"
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "invigorated-bulwark": {
              "amount": ""
            },
            "honorable-staff": {},
            "force-of-will": {}
          },
          {
            "imbued-haste": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Healbrand
    profession: Firebrand
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "46",
          "49",
          "62"
        ],
        "selectedTraits": [
          [
            617,
            610,
            554
          ],
          [
            1899,
            557,
            558
          ],
          [
            2101,
            2063,
            2105
          ]
        ],
        "items": [
          {
            "inspired-virtue": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": ""
            },
            "power-of-the-virtuous": {}
          },
          {
            "invigorated-bulwark": {
              "amount": ""
            },
            "honorable-staff": {},
            "force-of-will": {}
          },
          {
            "imbued-haste": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Heal Alacrity Willbender
    profession: Willbender
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "46",
          "49",
          "65"
        ],
        "selectedTraits": [
          [
            0,
            0,
            554
          ],
          [
            1899,
            562,
            558
          ],
          [
            0,
            2210,
            2195
          ]
        ],
        "items": [
          {
            "inspired-virtue": {
              "amount": ""
            },
            "unscathed-contender-health": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": ""
            },
            "power-of-the-virtuous": {}
          },
          {
            "invigorated-bulwark": {
              "amount": ""
            },
            "honorable-staff": {},
            "force-of-will": {}
          },
          {
            "searing-pact": {},
            "power-for-power": {},
            "conceited-curate": {},
            "lethal-tempo": {
              "amount": ""
            },
            "tyrants-momentum": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Willbender Radiance
    profession: Willbender
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "42",
          "65"
        ],
        "selectedTraits": [
          [
            566,
            565,
            1683
          ],
          [
            634,
            653,
            2017
          ],
          [
            2190,
            2197,
            2201
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": ""
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "fiery-wrath": {},
            "symbolic-exposure": {},
            "zealous-blade-base": {},
            "zealous-blade-gs": {
              "amount": ""
            },
            "kindled-zeal": {},
            "symbolic-avenger": {}
          },
          {
            "power-for-power": {},
            "lethal-tempo": {
              "amount": ""
            },
            "tyrants-momentum": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "bane-signet": {
            "amount": ""
          }
        }
      }

  - name: Power Willbender Virtues
    profession: Willbender
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "46",
          "42",
          "65"
        ],
        "selectedTraits": [
          [
            624,
            603,
            622
          ],
          [
            634,
            653,
            2017
          ],
          [
            2190,
            2197,
            2201
          ]
        ],
        "items": [
          {
            "inspired-virtue": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": "79"
            },
            "power-of-the-virtuous": {},
            "unscathed-contender-health": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            }
          },
          {
            "fiery-wrath": {},
            "symbolic-exposure": {},
            "zealous-blade-base": {},
            "zealous-blade-gs": {
              "amount": ""
            },
            "kindled-zeal": {},
            "symbolic-avenger": {}
          },
          {
            "power-for-power": {},
            "lethal-tempo": {
              "amount": ""
            },
            "tyrants-momentum": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "bane-signet": {
            "amount": ""
          }
        }
      }

  - name: Condi Alacrity Willbender
    profession: Willbender
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "16",
          "46",
          "65"
        ],
        "selectedTraits": [
          [
            566,
            567,
            1686
          ],
          [
            617,
            603,
            554
          ],
          [
            2191,
            2197,
            2195
          ]
        ],
        "items": [
          {
            "right-hand-strength": {},
            "right-hand-strength-1h": {
              "amount": "100"
            },
            "radiant-fire": {},
            "retribution": {
              "amount": ""
            },
            "radiant-power": {},
            "amplified-wrath": {},
            "righteous-instincts": {}
          },
          {
            "inspired-virtue": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": ""
            },
            "power-of-the-virtuous": {}
          },
          {
            "searing-pact": {},
            "power-for-power": {},
            "lethal-tempo": {
              "amount": ""
            },
            "tyrants-momentum": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-wrath": {
            "amount": ""
          }
        }
      }

  - name: Power Alacrity Willbender
    profession: Willbender
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "46",
          "42",
          "65"
        ],
        "selectedTraits": [
          [
            624,
            603,
            554
          ],
          [
            634,
            653,
            2017
          ],
          [
            2190,
            2197,
            2195
          ]
        ],
        "items": [
          {
            "inspired-virtue": {
              "amount": ""
            },
            "unscathed-contender": {
              "amount": ""
            },
            "virtue-of-resolution": {},
            "inspiring-virtue": {
              "amount": ""
            },
            "power-of-the-virtuous": {}
          },
          {
            "fiery-wrath": {},
            "symbolic-exposure": {},
            "zealous-blade-base": {},
            "zealous-blade-gs": {
              "amount": ""
            },
            "kindled-zeal": {},
            "symbolic-avenger": {}
          },
          {
            "power-for-power": {},
            "lethal-tempo": {
              "amount": ""
            },
            "tyrants-momentum": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "bane-signet": {
            "amount": ""
          }
        }
      }

  - name: Power Berserker GS A/A
    profession: Berserker
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "4",
          "51",
          "18"
        ],
        "selectedTraits": [
          [
            1444,
            1449,
            1437
          ],
          [
            1413,
            1489,
            1369
          ],
          [
            2049,
            2011,
            1928
          ]
        ],
        "items": [
          {
            "peak-performance": {},
            "forceful-greatsword": {},
            "great-fortitude": {},
            "pinnacle-of-strength": {
              "amount": ""
            },
            "pinnacle-of-strength-crit": {},
            "berserkers-power": {
              "amount": "4"
            }
          },
          {
            "warriors-sprint": {
              "amount": ""
            },
            "double-standards-str": {},
            "double-standards-disc": {},
            "axe-mastery-one": {},
            "axe-mastery-axe": {
              "amount": "73"
            }
          },
          {
            "blood-reaction-base": {},
            "blood-reaction-with": {
              "amount": "100"
            },
            "fatal-frenzy-power-condi": {
              "amount": "100"
            },
            "fatal-frenzy-toughness": {},
            "bloody-roar": {
              "amount": "100"
            },
            "king-of-fires": {},
            "smash-brawler-full": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-might": {},
          "signet-of-fury": {}
        }
      }

  - name: Quickness Power Berserker GS A/A
    profession: Berserker
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "4",
          "51",
          "18"
        ],
        "selectedTraits": [
          [
            1444,
            1449,
            1437
          ],
          [
            1413,
            1489,
            1369
          ],
          [
            2049,
            2042,
            1928
          ]
        ],
        "items": [
          {
            "peak-performance": {},
            "forceful-greatsword": {},
            "great-fortitude": {},
            "pinnacle-of-strength": {
              "amount": ""
            },
            "pinnacle-of-strength-crit": {},
            "berserkers-power": {
              "amount": "4"
            }
          },
          {
            "warriors-sprint": {
              "amount": ""
            },
            "double-standards-str": {},
            "double-standards-disc": {},
            "axe-mastery-one": {},
            "axe-mastery-axe": {
              "amount": "73"
            }
          },
          {
            "blood-reaction-base": {},
            "blood-reaction-with": {
              "amount": "100"
            },
            "fatal-frenzy-power-condi": {
              "amount": "100"
            },
            "fatal-frenzy-toughness": {},
            "bloody-roar": {
              "amount": "100"
            },
            "king-of-fires": {},
            "smash-brawler-full": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-might": {},
          "signet-of-fury": {}
        }
      }

  - name: Condi Quickness Berserker
    profession: Berserker
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "36",
          "51",
          "18"
        ],
        "selectedTraits": [
          [
            1455,
            1333,
            1346
          ],
          [
            1413,
            1709,
            1657
          ],
          [
            2039,
            2042,
            2038
          ]
        ],
        "items": [
          {
            "furious-burst": {},
            "wounding-precision": {},
            "signet-mastery": {
              "amount": ""
            },
            "deep-strikes-fury": {
              "amount": ""
            },
            "deep-strikes-bleeding": {},
            "unsuspecting-foe-full": {},
            "burst-precision": {
              "amount": ""
            },
            "blade-master": {},
            "blade-master-2": {
              "amount": ""
            },
            "bloodlust": {},
            "furious": {
              "amount": "18.9"
            },
            "bloodlust-on-crit": {
              "amount": "2.94"
            }
          },
          {
            "warriors-sprint": {
              "amount": ""
            },
            "axe-mastery-one": {},
            "axe-mastery-axe": {
              "amount": ""
            }
          },
          {
            "blood-reaction-base": {},
            "blood-reaction-with": {
              "amount": ""
            },
            "fatal-frenzy-power-condi": {
              "amount": "99"
            },
            "fatal-frenzy-toughness": {},
            "bloody-roar": {
              "amount": ""
            },
            "king-of-fires": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi Berserker
    profession: Berserker
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "36",
          "51",
          "18"
        ],
        "selectedTraits": [
          [
            1455,
            1333,
            1346
          ],
          [
            1413,
            1709,
            1657
          ],
          [
            2039,
            2011,
            2038
          ]
        ],
        "items": [
          {
            "furious-burst": {},
            "wounding-precision": {},
            "signet-mastery": {
              "amount": ""
            },
            "deep-strikes-fury": {
              "amount": ""
            },
            "deep-strikes-bleeding": {},
            "unsuspecting-foe-full": {},
            "burst-precision": {
              "amount": ""
            },
            "blade-master": {},
            "blade-master-2": {
              "amount": ""
            },
            "bloodlust": {},
            "furious": {
              "amount": "21.5"
            },
            "bloodlust-on-crit": {
              "amount": "2.95"
            }
          },
          {
            "warriors-sprint": {
              "amount": ""
            },
            "axe-mastery-one": {},
            "axe-mastery-axe": {
              "amount": ""
            }
          },
          {
            "blood-reaction-base": {},
            "blood-reaction-with": {
              "amount": ""
            },
            "fatal-frenzy-power-condi": {
              "amount": "97"
            },
            "fatal-frenzy-toughness": {},
            "bloody-roar": {
              "amount": ""
            },
            "king-of-fires": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  # Tether uptime can be calculated from Damage Modifiers tab
  - name: Power Spellbreaker GS
    profession: Spellbreaker
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "4",
          "11",
          "61"
        ],
        "selectedTraits": [
          [
            1444,
            1449,
            1437
          ],
          [
            1469,
            1486,
            0
          ],
          [
            2107,
            2095,
            2060
          ]
        ],
        "items": [
          {
            "peak-performance": {},
            "forceful-greatsword": {},
            "great-fortitude": {},
            "pinnacle-of-strength": {
              "amount": ""
            },
            "pinnacle-of-strength-crit": {},
            "berserkers-power": {
              "amount": "4"
            },
            "peak-performance-2": {
              "amount": "29.3"
            }
          },
          {
            "leg-specialist": {
              "amount": ""
            },
            "roaring-reveille": {},
            "empowered": {
              "amount": ""
            },
            "warriors-cunning": {
              "amount": ""
            },
            "vigorous-shouts": {}
          },
          {
            "pure-strike-base": {},
            "pure-strike-boonless": {
              "amount": ""
            },
            "attackers-insight": {
              "amount": "2.8"
            },
            "magebane-tether": {
              "amount": "75.3"
            },
            "sun-and-moon-style": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-might": {},
          "signet-of-fury": {}
        }
      }

  # Uses integer attacker's insight (which gives less accurate dps) so that
  # gear may be calculated properly for crit cap
  - name: Power Spellbreaker Hammer
    profession: Spellbreaker
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "4",
          "22",
          "61"
        ],
        "selectedTraits": [
          [
            1444,
            1449,
            1437
          ],
          [
            1372,
            1367,
            1708
          ],
          [
            2107,
            2095,
            2060
          ]
        ],
        "items": [
          {
            "peak-performance": {},
            "forceful-greatsword": {},
            "great-fortitude": {},
            "pinnacle-of-strength": {
              "amount": ""
            },
            "pinnacle-of-strength-crit": {},
            "berserkers-power": {
              "amount": "4"
            },
            "peak-performance-2": {
              "amount": "30"
            }
          },
          {
            "cull-the-weak": {
              "amount": ""
            },
            "merciless-hammer": {
              "amount": "40"
            },
            "hardened-armor": {},
            "stalwart-strength": {
              "amount": ""
            }
          },
          {
            "pure-strike-base": {},
            "pure-strike-boonless": {
              "amount": ""
            },
            "attackers-insight": {
              "amount": "5"
            },
            "magebane-tether": {
              "amount": "74"
            },
            "sun-and-moon-style": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-might": {},
          "signet-of-fury": {}
        }
      }

  - name: Alacrity Bladesworn
    profession: Bladesworn
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "4",
          "11",
          "68"
        ],
        "selectedTraits": [
          [
            1444,
            1449,
            1437
          ],
          [
            1469,
            1486,
            1667
          ],
          [
            2225,
            2302,
            2245
          ]
        ],
        "items": [
          {
            "peak-performance": {},
            "forceful-greatsword": {},
            "great-fortitude": {},
            "pinnacle-of-strength": {
              "amount": ""
            },
            "pinnacle-of-strength-crit": {},
            "berserkers-power": {
              "amount": "3.9"
            }
          },
          {
            "leg-specialist": {
              "amount": ""
            },
            "roaring-reveille": {},
            "empowered": {
              "amount": ""
            },
            "warriors-cunning": {
              "amount": ""
            },
            "vigorous-shouts": {}
          },
          {
            "fierce-as-fire": {
              "amount": "10"
            },
            "guns-and-glory": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: DPS Bladesworn Tactics
    profession: Bladesworn
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "4",
          "11",
          "68"
        ],
        "selectedTraits": [
          [
            1444,
            1449,
            1437
          ],
          [
            1469,
            1486,
            0
          ],
          [
            2225,
            2302,
            2239
          ]
        ],
        "items": [
          {
            "peak-performance": {},
            "forceful-greatsword": {},
            "great-fortitude": {},
            "pinnacle-of-strength": {
              "amount": ""
            },
            "pinnacle-of-strength-crit": {},
            "berserkers-power": {
              "amount": "4"
            },
            "peak-performance-2": {
              "amount": "4.1"
            }
          },
          {
            "leg-specialist": {
              "amount": ""
            },
            "roaring-reveille": {},
            "empowered": {
              "amount": ""
            },
            "warriors-cunning": {
              "amount": ""
            },
            "vigorous-shouts": {}
          },
          {
            "fierce-as-fire": {
              "amount": "10"
            },
            "guns-and-glory": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Heal Quickness Berserker
    profession: Berserker
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "11",
          "51",
          "18"
        ],
        "selectedTraits": [
          [
            1474,
            1482,
            1470
          ],
          [
            1381,
            1484,
            1657
          ],
          [
            2049,
            2042,
            2307
          ]
        ],
        "items": [
          {
            "leg-specialist": {
              "amount": ""
            },
            "roaring-reveille": {},
            "empowered": {
              "amount": ""
            },
            "warriors-cunning": {
              "amount": ""
            },
            "vigorous-shouts": {}
          },
          {
            "warriors-sprint": {
              "amount": ""
            },
            "stalwart-focus": {},
            "axe-mastery-one": {},
            "axe-mastery-axe": {
              "amount": ""
            }
          },
          {
            "smash-brawler-full": {},
            "blood-reaction-base": {},
            "blood-reaction-with": {
              "amount": ""
            },
            "fatal-frenzy-power-condi": {
              "amount": ""
            },
            "bloody-roar": {
              "amount": ""
            },
            "king-of-fires": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Weaver Stormsoul Fractal
    profession: Weaver
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "41",
          "56"
        ],
        "selectedTraits": [
          [
            296,
            334,
            1510
          ],
          [
            232,
            1502,
            226
          ],
          [
            2115,
            2061,
            2131
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "51"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": "51"
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": ""
            }
          },
          {
            "ferocious-winds": {},
            "raging-storm": {
              "amount": ""
            },
            "stormsoul": {
              "amount": ""
            },
            "aeromancers-training": {},
            "aeromancers-training-2": {
              "amount": "49"
            },
            "bolt-to-the-heart": {
              "amount": ""
            },
            "fresh-air": {
              "amount": ""
            },
            "zephyrs-speed": {}
          },
          {
            "superior-elements": {},
            "masters-fortitude": {},
            "masters-fortitude-sword": {},
            "weavers-prowess": {},
            "swift-revenge": {
              "amount": ""
            },
            "elemental-polyphony-fire": {
              "amount": "76"
            },
            "elemental-polyphony-air": {
              "amount": "74"
            },
            "elements-of-rage-base": {},
            "elements-of-rage-buff": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Weaver Raid
    profession: Weaver
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "41",
          "56"
        ],
        "selectedTraits": [
          [
            296,
            334,
            1510
          ],
          [
            232,
            1502,
            226
          ],
          [
            2177,
            2061,
            2131
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "49.3"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": "49.3"
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": "9.6"
            }
          },
          {
            "ferocious-winds": {},
            "raging-storm": {
              "amount": ""
            },
            "stormsoul": {
              "amount": ""
            },
            "aeromancers-training": {},
            "aeromancers-training-2": {
              "amount": "43"
            },
            "bolt-to-the-heart": {
              "amount": ""
            },
            "fresh-air": {
              "amount": ""
            },
            "zephyrs-speed": {}
          },
          {
            "superior-elements": {},
            "masters-fortitude": {},
            "masters-fortitude-sword": {},
            "weavers-prowess": {},
            "swift-revenge": {
              "amount": ""
            },
            "elemental-polyphony-fire": {
              "amount": "76.9"
            },
            "elemental-polyphony-air": {
              "amount": "65.2"
            },
            "elements-of-rage-base": {},
            "elements-of-rage-buff": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "woven-fire": {
            "amount": "50.1"
          },
          "woven-air": {
            "amount": "53.1"
          }
        }
      }

  - name: Condi Weaver Pistol
    profession: Weaver
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "26",
          "56"
        ],
        "selectedTraits": [
          [
            296,
            325,
            1510
          ],
          [
            1507,
            275,
            287
          ],
          [
            2177,
            2180,
            2131
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "23.8"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": ""
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": "6"
            }
          },
          {
            "serrated-stones": {},
            "strength-of-stone": {},
            "geomancers-training": {}
          },
          {
            "superior-elements": {},
            "masters-fortitude": {},
            "masters-fortitude-sword": {},
            "weavers-prowess": {},
            "swift-revenge": {
              "amount": ""
            },
            "elemental-polyphony-fire": {
              "amount": "44.5"
            },
            "elements-of-rage-base": {},
            "elements-of-rage-buff": {
              "amount": "95"
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "woven-fire": {
            "amount": "50.4"
          },
          "woven-air": {
            "amount": "11.2"
          },
          "signet-of-fire": {},
          "raging-ricochet": {
            "amount": "80"
          }
        }
      }

  - name: Condi Weaver Sword
    profession: Weaver
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "26",
          "56"
        ],
        "selectedTraits": [
          [
            296,
            325,
            1510
          ],
          [
            1507,
            275,
            287
          ],
          [
            2177,
            2180,
            2131
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": ""
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": ""
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": ""
            }
          },
          {
            "serrated-stones": {},
            "strength-of-stone": {},
            "geomancers-training": {}
          },
          {
            "superior-elements": {},
            "masters-fortitude": {},
            "masters-fortitude-sword": {},
            "weavers-prowess": {},
            "swift-revenge": {
              "amount": ""
            },
            "elemental-polyphony-fire": {
              "amount": "83"
            },
            "elemental-polyphony-air": {
              "amount": "29"
            },
            "elements-of-rage-base": {},
            "elements-of-rage-buff": {
              "amount": "80"
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "woven-fire": {
            "amount": "52.6"
          }
        }
      }

  - name: Condi Weaver Dagger
    profession: Weaver
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "26",
          "56"
        ],
        "selectedTraits": [
          [
            296,
            325,
            1510
          ],
          [
            1507,
            275,
            287
          ],
          [
            2177,
            2180,
            2131
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": ""
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": ""
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": ""
            }
          },
          {
            "serrated-stones": {},
            "strength-of-stone": {},
            "geomancers-training": {}
          },
          {
            "superior-elements": {},
            "masters-fortitude": {},
            "masters-fortitude-sword": {},
            "weavers-prowess": {},
            "swift-revenge": {
              "amount": ""
            },
            "elemental-polyphony-fire": {
              "amount": "83"
            },
            "elemental-polyphony-air": {
              "amount": "29"
            },
            "elements-of-rage-base": {},
            "elements-of-rage-buff": {
              "amount": "80"
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "woven-fire": {
            "amount": "55"
          }
        }
      }

  - name: Condi Weaver Staff
    profession: Weaver
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "26",
          "56"
        ],
        "selectedTraits": [
          [
            296,
            325,
            1510
          ],
          [
            1507,
            275,
            287
          ],
          [
            2177,
            2180,
            2131
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "54.9"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": ""
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": "9.7"
            }
          },
          {
            "serrated-stones": {},
            "strength-of-stone": {},
            "geomancers-training": {}
          },
          {
            "superior-elements": {},
            "masters-fortitude": {},
            "masters-fortitude-sword": {},
            "weavers-prowess": {},
            "swift-revenge": {
              "amount": ""
            },
            "elemental-polyphony-fire": {
              "amount": "80.6"
            },
            "elemental-polyphony-air": {
              "amount": "10.8"
            },
            "elements-of-rage-base": {},
            "elements-of-rage-buff": {
              "amount": "92"
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "woven-fire": {
            "amount": "54.8"
          },
          "woven-air": {
            "amount": "39"
          }
        }
      }

  - name: Condi Weaver Scepter
    profession: Weaver
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "26",
          "56"
        ],
        "selectedTraits": [
          [
            296,
            325,
            1510
          ],
          [
            1507,
            275,
            287
          ],
          [
            2177,
            2180,
            2131
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "66.3"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": ""
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": "6"
            }
          },
          {
            "serrated-stones": {},
            "strength-of-stone": {},
            "geomancers-training": {}
          },
          {
            "superior-elements": {},
            "masters-fortitude": {},
            "masters-fortitude-sword": {},
            "weavers-prowess": {},
            "swift-revenge": {
              "amount": ""
            },
            "elemental-polyphony-fire": {
              "amount": "95.8"
            },
            "elemental-polyphony-air": {
              "amount": "7.5"
            },
            "elements-of-rage-base": {},
            "elements-of-rage-buff": {
              "amount": "83.6"
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "woven-fire": {
            "amount": "53.2"
          },
          "woven-air": {
            "amount": "54.1"
          },
          "signet-of-fire": {}
        }
      }

  - name: Hybrid Weaver
    profession: Weaver
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "41",
          "56"
        ],
        "selectedTraits": [
          [
            296,
            325,
            1510
          ],
          [
            232,
            1502,
            1503
          ],
          [
            2177,
            2180,
            2131
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "53.3"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": ""
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": "9.5"
            }
          },
          {
            "ferocious-winds": {},
            "raging-storm": {
              "amount": ""
            },
            "stormsoul": {
              "amount": ""
            },
            "aeromancers-training": {},
            "aeromancers-training-2": {
              "amount": "35.3"
            },
            "bolt-to-the-heart": {
              "amount": ""
            },
            "fresh-air": {
              "amount": "48.5"
            },
            "zephyrs-speed": {}
          },
          {
            "superior-elements": {},
            "masters-fortitude": {},
            "masters-fortitude-sword": {},
            "weavers-prowess": {},
            "swift-revenge": {
              "amount": ""
            },
            "elemental-polyphony-fire": {
              "amount": "65.8"
            },
            "elemental-polyphony-air": {
              "amount": "63.1"
            },
            "elements-of-rage-base": {},
            "elements-of-rage-buff": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "woven-fire": {
            "amount": "53"
          },
          "woven-air": {
            "amount": "54.1"
          }
        }
      }

  - name: Alacrity Hybrid Tempest
    profession: Tempest
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "37",
          "48"
        ],
        "selectedTraits": [
          [
            296,
            325,
            1510
          ],
          [
            253,
            265,
            1511
          ],
          [
            1886,
            1891,
            2033
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "43.6"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": ""
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": "9.8"
            }
          },
          {
            "elemental-enchantment": {},
            "arcane-lightning": {
              "amount": ""
            },
            "bountiful-power": {
              "amount": "10.1"
            }
          },
          {
            "gathered-focus": {},
            "hardy-conduit": {},
            "transcendent-tempest": {
              "amount": "62"
            },
            "gathered-focus-2": {},
            "tempestuous-aria": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Alacrity Condi Tempest Pistol
    profession: Tempest
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "26",
          "48"
        ],
        "selectedTraits": [
          [
            296,
            325,
            1510
          ],
          [
            1507,
            275,
            287
          ],
          [
            1886,
            1891,
            2033
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "42"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": ""
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": "7.1"
            }
          },
          {
            "serrated-stones": {},
            "strength-of-stone": {},
            "geomancers-training": {}
          },
          {
            "gathered-focus": {},
            "hardy-conduit": {},
            "transcendent-tempest": {
              "amount": "61.5"
            },
            "tempestuous-aria": {
              "amount": ""
            },
            "gathered-focus-2": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-fire": {},
          "signet-of-earth": {}
        }
      }

  - name: Condi Tempest Pistol
    profession: Tempest
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "26",
          "48"
        ],
        "selectedTraits": [
          [
            296,
            325,
            1510
          ],
          [
            1507,
            275,
            287
          ],
          [
            1886,
            1891,
            1839
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "43"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": ""
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": "7.6"
            }
          },
          {
            "serrated-stones": {},
            "strength-of-stone": {},
            "geomancers-training": {}
          },
          {
            "gathered-focus": {},
            "hardy-conduit": {},
            "transcendent-tempest": {
              "amount": "61.5"
            },
            "tempestuous-aria": {
              "amount": ""
            },
            "gathered-focus-2": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-fire": {}
        }
      }

  - name: Condi Tempest Hammer
    profession: Tempest
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "26",
          "48"
        ],
        "selectedTraits": [
          [
            296,
            325,
            1510
          ],
          [
            1507,
            275,
            287
          ],
          [
            1886,
            1891,
            1839
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "63"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": ""
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": "6.2"
            }
          },
          {
            "serrated-stones": {},
            "strength-of-stone": {},
            "geomancers-training": {}
          },
          {
            "gathered-focus": {},
            "hardy-conduit": {},
            "transcendent-tempest": {
              "amount": "63"
            },
            "tempestuous-aria": {
              "amount": ""
            },
            "gathered-focus-2": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "crescent-wind": {},
          "flame-wheel": {
            "amount": ""
          },
          "signet-of-fire": {},
          "signet-of-earth": {}
        }
      }

  - name: Power Tempest Water
    profession: Tempest
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "17",
          "41",
          "48"
        ],
        "selectedTraits": [
          [
            363,
            349,
            2028
          ],
          [
            232,
            1502,
            1503
          ],
          [
            1886,
            1891,
            1839
          ]
        ],
        "items": [
          {
            "piercing-shards": {},
            "piercing-shards-2": {
              "amount": ""
            },
            "flow-like-water": {
              "amount": ""
            },
            "aquamancers-training": {},
            "soothing-power": {}
          },
          {
            "ferocious-winds": {},
            "raging-storm": {
              "amount": ""
            },
            "stormsoul": {
              "amount": ""
            },
            "aeromancers-training": {},
            "aeromancers-training-2": {
              "amount": "90"
            },
            "bolt-to-the-heart": {
              "amount": ""
            },
            "fresh-air": {
              "amount": "70"
            },
            "zephyrs-speed": {}
          },
          {
            "gathered-focus": {},
            "hardy-conduit": {},
            "transcendent-tempest": {
              "amount": "98"
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-fire": {}
        }
      }

  - name: Alacrity Power Tempest Arcane
    profession: Tempest
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "37",
          "41",
          "48"
        ],
        "selectedTraits": [
          [
            253,
            265,
            1511
          ],
          [
            232,
            1502,
            1503
          ],
          [
            1886,
            1891,
            2033
          ]
        ],
        "items": [
          {
            "elemental-enchantment": {},
            "arcane-lightning": {
              "amount": ""
            },
            "bountiful-power": {
              "amount": ""
            }
          },
          {
            "ferocious-winds": {},
            "raging-storm": {
              "amount": ""
            },
            "stormsoul": {
              "amount": ""
            },
            "aeromancers-training": {},
            "aeromancers-training-2": {
              "amount": "90"
            },
            "bolt-to-the-heart": {
              "amount": ""
            },
            "fresh-air": {
              "amount": "58"
            },
            "zephyrs-speed": {}
          },
          {
            "gathered-focus": {},
            "hardy-conduit": {},
            "transcendent-tempest": {
              "amount": "98"
            },
            "tempestuous-aria": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-fire": {}
        }
      }

  - name: Power Catalyst
    profession: Catalyst
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "31",
          "41",
          "67"
        ],
        "selectedTraits": [
          [
            296,
            334,
            1510
          ],
          [
            232,
            1502,
            226
          ],
          [
            2252,
            2247,
            2241
          ]
        ],
        "items": [
          {
            "empowering-flame": {
              "amount": "28"
            },
            "burning-precision": {},
            "burning-rage": {},
            "power-overwhelming": {},
            "power-overwhelming-2": {
              "amount": "28"
            },
            "pyromancers-training": {},
            "persisting-flames": {
              "amount": "5.4"
            }
          },
          {
            "ferocious-winds": {},
            "raging-storm": {
              "amount": ""
            },
            "stormsoul": {
              "amount": ""
            },
            "aeromancers-training": {},
            "aeromancers-training-2": {
              "amount": "18.6"
            },
            "bolt-to-the-heart": {
              "amount": ""
            },
            "fresh-air": {
              "amount": ""
            },
            "zephyrs-speed": {}
          },
          {
            "hardened-auras": {
              "amount": ""
            },
            "elemental-empowerment": {},
            "empowering-auras": {
              "amount": "4.6"
            },
            "empowered-empowerment": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "flame-wheel": {
            "amount": "99"
          },
          "crescent-wind": {},
          "relentless-fire": {
            "amount": "50.6"
          }
        }
      }

  - name: Power Quickness Catalyst
    profession: Catalyst
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "37",
          "41",
          "67"
        ],
        "selectedTraits": [
          [
            253,
            265,
            1511
          ],
          [
            232,
            1502,
            226
          ],
          [
            2252,
            2234,
            2251
          ]
        ],
        "items": [
          {
            "elemental-enchantment": {},
            "arcane-lightning": {
              "amount": ""
            },
            "bountiful-power": {
              "amount": ""
            }
          },
          {
            "ferocious-winds": {},
            "raging-storm": {
              "amount": ""
            },
            "stormsoul": {
              "amount": ""
            },
            "aeromancers-training": {},
            "aeromancers-training-2": {
              "amount": "32.2"
            },
            "bolt-to-the-heart": {
              "amount": ""
            },
            "fresh-air": {
              "amount": ""
            },
            "zephyrs-speed": {}
          },
          {
            "hardened-auras": {
              "amount": ""
            },
            "elemental-empowerment": {},
            "empowering-auras": {
              "amount": "4.8"
            },
            "empowered-empowerment": {},
            "spectacular-sphere": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "flame-wheel": {
            "amount": "94.4"
          },
          "crescent-wind": {},
          "relentless-fire": {
            "amount": "38.4"
          }
        }
      }

  - name: Heal Tempest
    profession: Tempest
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "37",
          "17",
          "48"
        ],
        "selectedTraits": [
          [
            253,
            265,
            238
          ],
          [
            363,
            358,
            2028
          ],
          [
            1962,
            2015,
            2033
          ]
        ],
        "items": [
          {
            "elemental-enchantment": {},
            "arcane-lightning": {
              "amount": ""
            },
            "bountiful-power": {
              "amount": ""
            }
          },
          {
            "piercing-shards": {},
            "flow-like-water": {
              "amount": ""
            },
            "aquamancers-training": {}
          },
          {
            "gathered-focus": {},
            "hardy-conduit": {},
            "transcendent-tempest": {
              "amount": ""
            },
            "gathered-focus-2": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi Quickness Untamed
    profession: Untamed
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "30",
          "33",
          "72"
        ],
        "selectedTraits": [
          [
            1069,
            1846,
            1064
          ],
          [
            1099,
            1101,
            1701
          ],
          [
            2301,
            0,
            2271
          ]
        ],
        "items": [
          {
            "hidden-barbs": {},
            "hunters-tactics": {},
            "vicious-quarry": {},
            "sharpened-edges": {
              "amount": "4.66"
            }
          },
          {
            "oakheart-salve": {},
            "taste-for-danger": {},
            "ambidexterity": {},
            "ambidexterity-2": {
              "amount": "100"
            },
            "poison-master": {}
          },
          {
            "natural-fortitude": {},
            "vow-of-the-untamed-you": {
              "amount": "88.2"
            },
            "ferocious-symbiosis": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "pet-dps": {
            "amount": "3552"
          }
        }
      }

  - name: Power Soulbeast
    profession: Soulbeast
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "30",
          "32",
          "55"
        ],
        "selectedTraits": [
          [
            1069,
            1700,
            1888
          ],
          [
            1606,
            970,
            1066
          ],
          [
            2071,
            2161,
            2143
          ]
        ],
        "items": [
          {
            "hidden-barbs": {},
            "hunters-tactics": {},
            "vicious-quarry": {},
            "sharpened-edges": {
              "amount": "2.12"
            },
            "striders-strength": {},
            "striders-strength-sword": {
              "amount": ""
            }
          },
          {
            "honed-axes": {},
            "honed-axes-axe": {
              "amount": ""
            }
          },
          {
            "furious-strength": {
              "amount": ""
            },
            "twice-as-vicious": {
              "amount": ""
            },
            "oppressive-superiority": {},
            "archetype-ferocious": {},
            "pack-alpha-merged": {},
            "loud-whistle-merged": {},
            "pets-prowess-merged": {},
            "honed-axes-merged": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-the-wild": {},
          "one-wolf-pack-sic-em": {
            "amount": "0.1"
          }
        }
      }

  - name: Quickness Power Untamed
    profession: Untamed
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "30",
          "25",
          "72"
        ],
        "selectedTraits": [
          [
            1069,
            1700,
            1888
          ],
          [
            1062,
            965,
            1038
          ],
          [
            2301,
            0,
            2274
          ]
        ],
        "items": [
          {
            "hidden-barbs": {},
            "hunters-tactics": {},
            "vicious-quarry": {},
            "sharpened-edges": {
              "amount": "2.75"
            },
            "striders-strength": {},
            "striders-strength-sword": {
              "amount": ""
            }
          },
          {
            "bountiful-hunter": {
              "amount": ""
            },
            "wellspring": {},
            "lingering-magic": {}
          },
          {
            "natural-fortitude": {},
            "vow-of-the-untamed-you": {
              "amount": "90"
            },
            "ferocious-symbiosis": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-the-wild": {}
        }
      }

  - name: Power Untamed
    profession: Untamed
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "30",
          "8",
          "72"
        ],
        "selectedTraits": [
          [
            1069,
            1700,
            1888
          ],
          [
            0,
            1000,
            996
          ],
          [
            2301,
            0,
            2274
          ]
        ],
        "items": [
          {
            "hidden-barbs": {},
            "hunters-tactics": {},
            "vicious-quarry": {},
            "sharpened-edges": {
              "amount": "2.75"
            },
            "striders-strength": {},
            "striders-strength-sword": {
              "amount": ""
            }
          },
          {
            "farsighted": {},
            "predators-onslaught": {
              "amount": ""
            }
          },
          {
            "natural-fortitude": {},
            "vow-of-the-untamed-you": {
              "amount": "90"
            },
            "ferocious-symbiosis": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-the-wild": {}
        }
      }

  - name: Condi Slb D/D SB
    profession: Soulbeast
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "30",
          "33",
          "55"
        ],
        "selectedTraits": [
          [
            1069,
            1846,
            1912
          ],
          [
            1099,
            1101,
            1701
          ],
          [
            2071,
            2161,
            2128
          ]
        ],
        "items": [
          {
            "hidden-barbs": {},
            "hunters-tactics": {},
            "vicious-quarry": {},
            "sharpened-edges": {
              "amount": "3.06"
            }
          },
          {
            "oakheart-salve": {},
            "taste-for-danger": {},
            "ambidexterity": {},
            "ambidexterity-2": {
              "amount": "50"
            },
            "poison-master": {}
          },
          {
            "furious-strength": {
              "amount": ""
            },
            "twice-as-vicious": {
              "amount": "84.4"
            },
            "oppressive-superiority": {},
            "archetype-deadly": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "one-wolf-pack-no-sic-em-sharpened": {
            "amount": "0.14"
          },
          "vulture-stance": {
            "amount": "1.68"
          }
        }
      }

  - name: Hybrid Slb OS
    profession: Soulbeast
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "30",
          "32",
          "55"
        ],
        "selectedTraits": [
          [
            1069,
            1846,
            1888
          ],
          [
            1606,
            970,
            1066
          ],
          [
            2071,
            2161,
            2143
          ]
        ],
        "items": [
          {
            "hidden-barbs": {},
            "hunters-tactics": {},
            "vicious-quarry": {},
            "sharpened-edges": {
              "amount": "3.56"
            }
          },
          {
            "honed-axes": {},
            "honed-axes-axe": {
              "amount": "100"
            }
          },
          {
            "furious-strength": {
              "amount": ""
            },
            "twice-as-vicious": {
              "amount": "80.5"
            },
            "oppressive-superiority": {},
            "archetype-deadly": {},
            "pack-alpha-merged": {},
            "loud-whistle-merged": {},
            "pets-prowess-merged": {},
            "honed-axes-merged": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "one-wolf-pack-sic-em-sharpened": {
            "amount": "0.1682"
          },
          "vulture-stance": {
            "amount": "0.6326"
          }
        }
      }

  - name: Hybrid Slb
    profession: Soulbeast
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "30",
          "32",
          "55"
        ],
        "selectedTraits": [
          [
            1069,
            1846,
            1888
          ],
          [
            1072,
            970,
            1066
          ],
          [
            2071,
            2161,
            2128
          ]
        ],
        "items": [
          {
            "hidden-barbs": {},
            "hunters-tactics": {},
            "vicious-quarry": {},
            "sharpened-edges": {
              "amount": "3.62"
            }
          },
          {
            "honed-axes": {},
            "honed-axes-axe": {
              "amount": "100"
            }
          },
          {
            "furious-strength": {
              "amount": ""
            },
            "twice-as-vicious": {
              "amount": "81"
            },
            "oppressive-superiority": {},
            "archetype-deadly": {},
            "pack-alpha-merged": {},
            "loud-whistle-merged": {},
            "pets-prowess-merged": {},
            "honed-axes-merged": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "one-wolf-pack-sic-em-sharpened": {
            "amount": "0.21"
          },
          "vulture-stance": {
            "amount": "1.7"
          }
        }
      }

  - name: Condi Druid
    profession: Druid
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "30",
          "33",
          "5"
        ],
        "selectedTraits": [
          [
            1069,
            1846,
            1888
          ],
          [
            1099,
            1101,
            1701
          ],
          [
            1935,
            2056,
            2055
          ]
        ],
        "items": [
          {
            "hidden-barbs": {},
            "hunters-tactics": {},
            "vicious-quarry": {},
            "sharpened-edges": {
              "amount": "2.66"
            }
          },
          {
            "oakheart-salve": {},
            "taste-for-danger": {},
            "ambidexterity": {},
            "ambidexterity-2": {
              "amount": "100"
            },
            "poison-master": {}
          },
          {
            "natural-mender": {
              "amount": ""
            },
            "natural-balance": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "pet-dps": {
            "amount": "1865"
          }
        }
      }

  - name: Heal Druid
    profession: Druid
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "8",
          "25",
          "5"
        ],
        "selectedTraits": [
          [
            986,
            1070,
            996
          ],
          [
            978,
            965,
            1697
          ],
          [
            0,
            0,
            2057
          ]
        ],
        "items": [
          {
            "farsighted": {},
            "predators-onslaught": {
              "amount": ""
            }
          },
          {
            "bountiful-hunter": {
              "amount": ""
            },
            "wellspring": {},
            "lingering-magic": {}
          },
          {
            "natural-mender": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Alac Ren
    profession: Renegade
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "15",
          "3",
          "63"
        ],
        "selectedTraits": [
          [
            1767,
            1765,
            1800
          ],
          [
            1761,
            1774,
            1791
          ],
          [
            2166,
            2108,
            2182
          ]
        ],
        "items": [
          {
            "unsuspecting-strikes": {
              "amount": ""
            },
            "destructive-impulses-base": {},
            "destructive-impulses-dual": {
              "amount": "100"
            },
            "notoriety": {
              "amount": ""
            },
            "targeted-destruction": {
              "amount": ""
            },
            "swift-termination": {
              "amount": ""
            }
          },
          {
            "rising-tide": {
              "amount": ""
            },
            "ferocious-aggression": {
              "amount": ""
            },
            "roiling-mists": {}
          },
          {
            "ambush-commander": {
              "amount": ""
            },
            "blood-fury": {},
            "heartpiercer": {},
            "brutal-momentum": {},
            "lasting-legacy": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi Alac Ren Invo
    profession: Renegade
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "14",
          "3",
          "63"
        ],
        "selectedTraits": [
          [
            1793,
            1714,
            1795
          ],
          [
            1761,
            1781,
            1791
          ],
          [
            2079,
            2092,
            2182
          ]
        ],
        "items": [
          {
            "acolyte-of-torment": {},
            "seething-malice": {},
            "demonic-resistance": {},
            "pact-of-pain": {},
            "yearning-empowerment": {}
          },
          {
            "rising-tide": {
              "amount": ""
            },
            "ferocious-aggression": {
              "amount": ""
            },
            "roiling-mists": {}
          },
          {
            "ambush-commander": {
              "amount": ""
            },
            "blood-fury": {},
            "heartpiercer": {},
            "brutal-momentum": {},
            "lasting-legacy": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi DPS Ren Invo
    profession: Renegade
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "14",
          "3",
          "63"
        ],
        "selectedTraits": [
          [
            1793,
            1714,
            1795
          ],
          [
            1761,
            1781,
            1791
          ],
          [
            2079,
            2092,
            2100
          ]
        ],
        "items": [
          {
            "acolyte-of-torment": {},
            "seething-malice": {},
            "demonic-resistance": {},
            "pact-of-pain": {},
            "yearning-empowerment": {}
          },
          {
            "rising-tide": {
              "amount": ""
            },
            "ferocious-aggression": {
              "amount": ""
            },
            "roiling-mists": {}
          },
          {
            "ambush-commander": {
              "amount": ""
            },
            "blood-fury": {},
            "heartpiercer": {},
            "brutal-momentum": {},
            "lasting-legacy": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Quickness Herald
    profession: Herald
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "15",
          "3",
          "52"
        ],
        "selectedTraits": [
          [
            1767,
            1765,
            1800
          ],
          [
            1761,
            1774,
            1719
          ],
          [
            1806,
            1738,
            1746
          ]
        ],
        "items": [
          {
            "unsuspecting-strikes": {
              "amount": ""
            },
            "destructive-impulses-base": {},
            "destructive-impulses-dual": {
              "amount": "100"
            },
            "notoriety": {
              "amount": ""
            },
            "targeted-destruction": {
              "amount": ""
            },
            "swift-termination": {
              "amount": ""
            }
          },
          {
            "rising-tide": {
              "amount": ""
            },
            "ferocious-aggression": {
              "amount": ""
            },
            "roiling-mists": {}
          },
          {
            "draconic-fortitude": {},
            "reinforced-potency-base": {},
            "reinforced-potency-boons": {
              "amount": ""
            },
            "forceful-persistence-nonherald": {
              "amount": ""
            },
            "forceful-persistence-herald": {
              "amount": ""
            },
            "elevated-compassion": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "burst-of-strength": {
            "amount": "90.4"
          }
        }
      }

  - name: Power Herald FP
    profession: Herald
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "15",
          "3",
          "52"
        ],
        "selectedTraits": [
          [
            1767,
            1765,
            1800
          ],
          [
            1761,
            1774,
            1719
          ],
          [
            1813,
            1738,
            1803
          ]
        ],
        "items": [
          {
            "unsuspecting-strikes": {
              "amount": ""
            },
            "destructive-impulses-base": {},
            "destructive-impulses-dual": {
              "amount": "100"
            },
            "notoriety": {
              "amount": ""
            },
            "targeted-destruction": {
              "amount": ""
            },
            "swift-termination": {
              "amount": ""
            }
          },
          {
            "rising-tide": {
              "amount": ""
            },
            "ferocious-aggression": {
              "amount": ""
            },
            "roiling-mists": {}
          },
          {
            "draconic-fortitude": {},
            "reinforced-potency-base": {},
            "reinforced-potency-boons": {
              "amount": ""
            },
            "forceful-persistence-nonherald": {
              "amount": "49.9"
            },
            "forceful-persistence-herald": {
              "amount": "2.23"
            }
          }
        ]
      }
    skills:
      {
        "skills": {
          "burst-of-strength": {
            "amount": "86.2"
          }
        }
      }

  - name: Condi Quickness Herald
    profession: Herald
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "14",
          "3",
          "52"
        ],
        "selectedTraits": [
          [
            1793,
            1714,
            1795
          ],
          [
            1761,
            1774,
            1749
          ],
          [
            1806,
            1738,
            1746
          ]
        ],
        "items": [
          {
            "acolyte-of-torment": {},
            "seething-malice": {},
            "demonic-resistance": {},
            "pact-of-pain": {},
            "yearning-empowerment": {}
          },
          {
            "rising-tide": {
              "amount": ""
            },
            "ferocious-aggression": {
              "amount": ""
            },
            "roiling-mists": {}
          },
          {
            "draconic-fortitude": {},
            "reinforced-potency-base": {},
            "reinforced-potency-boons": {
              "amount": ""
            },
            "forceful-persistence-nonherald": {
              "amount": ""
            },
            "forceful-persistence-herald": {
              "amount": ""
            },
            "elevated-compassion": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "burst-of-strength": {
            "amount": "89.2"
          }
        }
      }

  - name: Heal Ren
    profession: Renegade
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "",
          "12",
          "63"
        ],
        "selectedTraits": [
          [
            0,
            0,
            0
          ],
          [
            1823,
            1819,
            1815
          ],
          [
            2120,
            2108,
            2182
          ]
        ],
        "items": [
          {},
          {
            "life-attunement": {},
            "invoking-harmony": {
              "amount": ""
            },
            "serene-rejuvenation": {}
          },
          {
            "ambush-commander": {
              "amount": ""
            },
            "blood-fury": {},
            "heartpiercer": {},
            "brutal-momentum": {},
            "lasting-legacy": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Heal Herald
    profession: Herald
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "",
          "12",
          "52"
        ],
        "selectedTraits": [
          [
            0,
            0,
            0
          ],
          [
            1823,
            1819,
            1815
          ],
          [
            1813,
            1738,
            1746
          ]
        ],
        "items": [
          {},
          {
            "life-attunement": {},
            "invoking-harmony": {
              "amount": ""
            },
            "serene-rejuvenation": {}
          },
          {
            "draconic-fortitude": {},
            "elevated-compassion": {},
            "reinforced-potency-base": {},
            "reinforced-potency-boons": {
              "amount": ""
            },
            "forceful-persistence-nonherald": {
              "amount": ""
            },
            "forceful-persistence-herald": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: DPS Vindicator
    profession: Vindicator
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "15",
          "3",
          "69"
        ],
        "selectedTraits": [
          [
            1767,
            1765,
            1800
          ],
          [
            1761,
            1774,
            1719
          ],
          [
            2258,
            2259,
            2257
          ]
        ],
        "items": [
          {
            "unsuspecting-strikes": {
              "amount": ""
            },
            "destructive-impulses-base": {},
            "destructive-impulses-dual": {
              "amount": ""
            },
            "notoriety": {
              "amount": ""
            },
            "targeted-destruction": {
              "amount": ""
            },
            "swift-termination": {
              "amount": ""
            }
          },
          {
            "rising-tide": {
              "amount": ""
            },
            "ferocious-aggression": {
              "amount": ""
            },
            "roiling-mists": {}
          },
          {
            "leviathan-strength": {
              "amount": ""
            },
            "empire-divided-above": {
              "amount": ""
            },
            "empire-divided-below": {
              "amount": ""
            },
            "forerunner-of-death": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Holo ECSU
    profession: Holosmith
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "38",
          "6",
          "57"
        ],
        "selectedTraits": [
          [
            1914,
            1923,
            526
          ],
          [
            1882,
            1892,
            1947
          ],
          [
            2106,
            2152,
            2137
          ]
        ],
        "items": [
          {
            "chemical-rounds": {},
            "high-caliber": {},
            "hematic-focus": {},
            "thermal-vision": {},
            "thermal-vision-buff": {},
            "no-scope": {
              "amount": ""
            },
            "serrated-steel": {},
            "modified-ammunition": {
              "amount": ""
            },
            "incendiary-powder": {},
            "sharpshooter": {
              "amount": "4.77"
            }
          },
          {
            "glass-cannon": {
              "amount": ""
            },
            "explosive-temper": {
              "amount": "9.181"
            },
            "blast-shield": {},
            "shaped-charge": {
              "amount": ""
            },
            "big-boomer": {}
          },
          {
            "lasers-edge": {
              "amount": "120"
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Holo PBM
    profession: Holosmith
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "38",
          "6",
          "57"
        ],
        "selectedTraits": [
          [
            1914,
            1923,
            526
          ],
          [
            1882,
            1892,
            1947
          ],
          [
            2106,
            2152,
            2064
          ]
        ],
        "items": [
          {
            "chemical-rounds": {},
            "high-caliber": {},
            "hematic-focus": {},
            "thermal-vision": {},
            "thermal-vision-buff": {},
            "no-scope": {
              "amount": ""
            },
            "serrated-steel": {},
            "modified-ammunition": {
              "amount": ""
            },
            "incendiary-powder": {},
            "sharpshooter": {
              "amount": "4.41"
            }
          },
          {
            "glass-cannon": {
              "amount": ""
            },
            "explosive-temper": {
              "amount": ""
            },
            "blast-shield": {},
            "shaped-charge": {
              "amount": ""
            },
            "big-boomer": {}
          },
          {
            "lasers-edge": {
              "amount": "50"
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi Holo
    profession: Holosmith
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "38",
          "6",
          "57"
        ],
        "selectedTraits": [
          [
            1878,
            2006,
            433
          ],
          [
            1882,
            482,
            505
          ],
          [
            2106,
            2103,
            2064
          ]
        ],
        "items": [
          {
            "chemical-rounds": {},
            "high-caliber": {},
            "hematic-focus": {},
            "thermal-vision": {},
            "thermal-vision-buff": {},
            "no-scope": {
              "amount": ""
            },
            "serrated-steel": {},
            "modified-ammunition": {
              "amount": ""
            },
            "incendiary-powder": {},
            "sharpshooter": {
              "amount": "4.66"
            }
          },
          {
            "glass-cannon": {
              "amount": ""
            },
            "explosive-temper": {
              "amount": ""
            },
            "blast-shield": {},
            "shaped-charge": {
              "amount": ""
            },
            "big-boomer": {}
          },
          {
            "lasers-edge": {
              "amount": "50"
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Alacrity Mechanist (inaccurate)
    profession: Mechanist
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "38",
          "6",
          "70"
        ],
        "selectedTraits": [
          [
            1914,
            1923,
            526
          ],
          [
            1882,
            1892,
            1947
          ],
          [
            2296,
            2276,
            2281
          ]
        ],
        "items": [
          {
            "chemical-rounds": {},
            "high-caliber": {},
            "hematic-focus": {},
            "thermal-vision": {},
            "thermal-vision-buff": {},
            "no-scope": {
              "amount": ""
            },
            "serrated-steel": {},
            "modified-ammunition": {
              "amount": ""
            },
            "incendiary-powder": {},
            "sharpshooter": {
              "amount": "???"
            }
          },
          {
            "glass-cannon": {
              "amount": ""
            },
            "explosive-temper": {
              "amount": ""
            },
            "blast-shield": {},
            "shaped-charge": {
              "amount": ""
            },
            "big-boomer": {}
          },
          {}
        ]
      }
    skills:
      {
        "skills": {
          "force-signet": {}
        }
      }

  - name: Condi Mechanist J-Drive
    profession: Mechanist
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "38",
          "6",
          "70"
        ],
        "selectedTraits": [
          [
            1878,
            2006,
            433
          ],
          [
            1882,
            482,
            505
          ],
          [
            2282,
            2270,
            2298
          ]
        ],
        "items": [
          {
            "chemical-rounds": {},
            "high-caliber": {},
            "hematic-focus": {},
            "thermal-vision": {},
            "thermal-vision-buff": {},
            "no-scope": {},
            "serrated-steel": {},
            "modified-ammunition": {
              "amount": ""
            },
            "incendiary-powder": {},
            "sharpshooter": {
              "amount": "3.24"
            }
          },
          {
            "glass-cannon": {
              "amount": ""
            },
            "explosive-temper": {
              "amount": ""
            },
            "blast-shield": {},
            "shaped-charge": {
              "amount": ""
            },
            "big-boomer": {}
          },
          {}
        ]
      }
    skills:
      {
        "skills": {
          "superconducting-signet-traited": {}
        }
      }

  - name: Condi Mechanist Jade Dynamo
    profession: Mechanist
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "38",
          "6",
          "70"
        ],
        "selectedTraits": [
          [
            1878,
            2006,
            433
          ],
          [
            1882,
            1892,
            505
          ],
          [
            2282,
            2270,
            2292
          ]
        ],
        "items": [
          {
            "chemical-rounds": {},
            "high-caliber": {},
            "hematic-focus": {},
            "thermal-vision": {},
            "thermal-vision-buff": {},
            "no-scope": {
              "amount": ""
            },
            "serrated-steel": {},
            "modified-ammunition": {
              "amount": ""
            },
            "incendiary-powder": {},
            "sharpshooter": {
              "amount": "4.01"
            }
          },
          {
            "glass-cannon": {
              "amount": ""
            },
            "explosive-temper": {
              "amount": ""
            },
            "blast-shield": {},
            "shaped-charge": {
              "amount": ""
            },
            "big-boomer": {}
          },
          {}
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi Alac Mechanist (inaccurate)
    profession: Mechanist
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "38",
          "6",
          "70"
        ],
        "selectedTraits": [
          [
            1878,
            2006,
            433
          ],
          [
            1882,
            1892,
            505
          ],
          [
            2296,
            2276,
            2281
          ]
        ],
        "items": [
          {
            "chemical-rounds": {},
            "high-caliber": {},
            "hematic-focus": {},
            "thermal-vision": {},
            "thermal-vision-buff": {},
            "no-scope": {
              "amount": ""
            },
            "serrated-steel": {},
            "modified-ammunition": {
              "amount": ""
            },
            "incendiary-powder": {},
            "sharpshooter": {
              "amount": "4"
            }
          },
          {
            "glass-cannon": {
              "amount": ""
            },
            "explosive-temper": {
              "amount": ""
            },
            "blast-shield": {},
            "shaped-charge": {
              "amount": ""
            },
            "big-boomer": {}
          },
          {}
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Scrapper
    profession: Scrapper
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "38",
          "6",
          "43"
        ],
        "selectedTraits": [
          [
            1914,
            1923,
            526
          ],
          [
            1882,
            482,
            1947
          ],
          [
            1917,
            1860,
            1849
          ]
        ],
        "items": [
          {
            "chemical-rounds": {},
            "high-caliber": {},
            "hematic-focus": {},
            "thermal-vision": {},
            "thermal-vision-buff": {},
            "no-scope": {
              "amount": ""
            },
            "serrated-steel": {},
            "modified-ammunition": {
              "amount": ""
            },
            "incendiary-powder": {},
            "sharpshooter": {
              "amount": "4.2"
            }
          },
          {
            "glass-cannon": {
              "amount": ""
            },
            "explosive-temper": {
              "amount": ""
            },
            "blast-shield": {},
            "shaped-charge": {
              "amount": ""
            },
            "big-boomer": {}
          },
          {
            "object-in-motion": {
              "amount": "2.2"
            },
            "impact-savant": {},
            "kinetic-accelerators": {},
            "applied-force": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Quickness Power Scrapper
    profession: Scrapper
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "38",
          "6",
          "43"
        ],
        "selectedTraits": [
          [
            1914,
            1923,
            526
          ],
          [
            1882,
            482,
            1947
          ],
          [
            1917,
            1860,
            2052
          ]
        ],
        "items": [
          {
            "chemical-rounds": {},
            "high-caliber": {},
            "hematic-focus": {},
            "thermal-vision": {},
            "thermal-vision-buff": {},
            "no-scope": {
              "amount": ""
            },
            "serrated-steel": {},
            "modified-ammunition": {
              "amount": ""
            },
            "incendiary-powder": {},
            "sharpshooter": {
              "amount": "4.2"
            }
          },
          {
            "glass-cannon": {
              "amount": ""
            },
            "explosive-temper": {
              "amount": ""
            },
            "blast-shield": {},
            "shaped-charge": {
              "amount": ""
            },
            "big-boomer": {}
          },
          {
            "object-in-motion": {
              "amount": "2.2"
            },
            "impact-savant": {},
            "kinetic-accelerators": {},
            "applied-force": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Quickness Condi Scrapper
    profession: Scrapper
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "38",
          "6",
          "43"
        ],
        "selectedTraits": [
          [
            1878,
            2006,
            433
          ],
          [
            1882,
            482,
            505
          ],
          [
            1917,
            1860,
            2052
          ]
        ],
        "items": [
          {
            "chemical-rounds": {},
            "high-caliber": {},
            "hematic-focus": {},
            "thermal-vision": {},
            "thermal-vision-buff": {},
            "no-scope": {
              "amount": ""
            },
            "serrated-steel": {},
            "modified-ammunition": {
              "amount": ""
            },
            "incendiary-powder": {},
            "sharpshooter": {
              "amount": "3.87"
            }
          },
          {
            "glass-cannon": {
              "amount": ""
            },
            "explosive-temper": {
              "amount": ""
            },
            "blast-shield": {},
            "shaped-charge": {
              "amount": ""
            },
            "big-boomer": {}
          },
          {
            "object-in-motion": {
              "amount": ""
            },
            "impact-savant": {},
            "kinetic-accelerators": {},
            "applied-force": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Heal Scrapper
    profession: Scrapper
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "47",
          "29",
          "43"
        ],
        "selectedTraits": [
          [
            394,
            1834,
            1916
          ],
          [
            521,
            470,
            473
          ],
          [
            1917,
            1999,
            2052
          ]
        ],
        "items": [
          {
            "over-shield": {},
            "energy-amplifier": {}
          },
          {
            "compounding-chemicals": {}
          },
          {
            "object-in-motion": {
              "amount": ""
            },
            "impact-savant": {},
            "kinetic-accelerators": {},
            "applied-force": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Heal Mechanist
    profession: Mechanist
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "47",
          "29",
          "70"
        ],
        "selectedTraits": [
          [
            394,
            1834,
            1916
          ],
          [
            521,
            470,
            473
          ],
          [
            2296,
            2276,
            2281
          ]
        ],
        "items": [
          {
            "over-shield": {},
            "energy-amplifier": {}
          },
          {
            "compounding-chemicals": {}
          },
          {}
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi Alacrity Scourge
    profession: Scourge
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "39",
          "50",
          "60"
        ],
        "selectedTraits": [
          [
            2013,
            816,
            801
          ],
          [
            875,
            894,
            905
          ],
          [
            2074,
            2123,
            2080
          ]
        ],
        "items": [
          {
            "barbed-precision": {},
            "furious-demise": {},
            "target-the-weak-base": {},
            "target-the-weak": {
              "amount": ""
            },
            "lingering-curse": {},
            "barbed-precision-on-crit": {
              "amount": "2.85"
            }
          },
          {
            "soul-barbs": {
              "amount": ""
            },
            "vital-persistence": {},
            "death-perception": {}
          },
          {
            "fell-beacon": {},
            "demonic-lore": {},
            "sand-sage-active": {},
            "blood-as-sand-active": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-spite": {
            "amount": "12.4"
          }
        }
      }

  - name: DPS Scourge
    profession: Scourge
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "39",
          "50",
          "60"
        ],
        "selectedTraits": [
          [
            2013,
            816,
            801
          ],
          [
            875,
            894,
            905
          ],
          [
            2074,
            2067,
            2164
          ]
        ],
        "items": [
          {
            "barbed-precision": {},
            "furious-demise": {},
            "target-the-weak-base": {},
            "target-the-weak": {
              "amount": ""
            },
            "lingering-curse": {},
            "barbed-precision-on-crit": {
              "amount": "3.06"
            }
          },
          {
            "soul-barbs": {
              "amount": ""
            },
            "vital-persistence": {},
            "death-perception": {}
          },
          {
            "fell-beacon": {},
            "demonic-lore": {},
            "sand-sage-active": {},
            "blood-as-sand-active": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-spite": {
            "amount": "12.9"
          }
        }
      }

  - name: Power Reaper
    profession: Reaper
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "53",
          "50",
          "34"
        ],
        "selectedTraits": [
          [
            914,
            829,
            853
          ],
          [
            875,
            894,
            893
          ],
          [
            2020,
            1969,
            2021
          ]
        ],
        "items": [
          {
            "spiteful-talisman": {
              "amount": ""
            },
            "awaken-the-pain": {
              "amount": ""
            },
            "close-to-death": {
              "amount": ""
            }
          },
          {
            "soul-barbs": {
              "amount": ""
            },
            "vital-persistence": {},
            "death-perception": {}
          },
          {
            "soul-eater": {
              "amount": ""
            },
            "decimate-defenses-25x": {},
            "cold-shoulder": {
              "amount": ""
            },
            "reapers-onslaught": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi Reaper Spear
    profession: Reaper
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "39",
          "50",
          "34"
        ],
        "selectedTraits": [
          [
            815,
            816,
            801
          ],
          [
            898,
            894,
            893
          ],
          [
            2020,
            1969,
            1919
          ]
        ],
        "items": [
          {
            "barbed-precision": {},
            "furious-demise": {},
            "target-the-weak-base": {},
            "target-the-weak": {
              "amount": ""
            },
            "lingering-curse": {},
            "barbed-precision-on-crit": {
              "amount": "3.73"
            }
          },
          {
            "soul-barbs": {
              "amount": "96"
            },
            "vital-persistence": {},
            "death-perception": {}
          },
          {
            "soul-eater": {
              "amount": ""
            },
            "decimate-defenses-25x": {},
            "cold-shoulder": {
              "amount": ""
            },
            "reapers-onslaught": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "soul-shards": {
            "amount": "4.83"
          },
          "soul-shards-below": {
            "amount": "4.83"
          }
        }
      }

  - name: Condi Reaper Scepter
    profession: Reaper
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "39",
          "50",
          "34"
        ],
        "selectedTraits": [
          [
            815,
            816,
            801
          ],
          [
            898,
            894,
            893
          ],
          [
            2020,
            1969,
            1919
          ]
        ],
        "items": [
          {
            "barbed-precision": {},
            "furious-demise": {},
            "target-the-weak-base": {},
            "target-the-weak": {
              "amount": ""
            },
            "lingering-curse": {},
            "barbed-precision-on-crit": {
              "amount": "3.23"
            }
          },
          {
            "soul-barbs": {
              "amount": "96"
            },
            "vital-persistence": {},
            "death-perception": {}
          },
          {
            "soul-eater": {
              "amount": ""
            },
            "decimate-defenses-25x": {},
            "cold-shoulder": {
              "amount": ""
            },
            "reapers-onslaught": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Heal Scourge
    profession: Scourge
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "19",
          "50",
          "60"
        ],
        "selectedTraits": [
          [
            780,
            789,
            778
          ],
          [
            875,
            892,
            889
          ],
          [
            2167,
            2123,
            2080
          ]
        ],
        "items": [
          {
            "life-from-death": {}
          },
          {
            "soul-barbs": {
              "amount": ""
            },
            "vital-persistence": {},
            "death-perception": {}
          },
          {
            "fell-beacon": {},
            "sand-sage-active": {},
            "blood-as-sand-active": {},
            "demonic-lore": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: DPS Harbinger
    profession: Harbinger
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "39",
          "50",
          "64"
        ],
        "selectedTraits": [
          [
            1883,
            816,
            801
          ],
          [
            875,
            894,
            905
          ],
          [
            2185,
            2209,
            2203
          ]
        ],
        "items": [
          {
            "barbed-precision": {},
            "furious-demise": {},
            "target-the-weak-base": {},
            "target-the-weak": {
              "amount": ""
            },
            "lingering-curse": {},
            "barbed-precision-on-crit": {
              "amount": "3.05"
            }
          },
          {
            "soul-barbs": {
              "amount": ""
            },
            "vital-persistence": {},
            "death-perception": {}
          },
          {
            "wicked-corruption": {
              "amount": ""
            },
            "septic-corruption": {
              "amount": "20.8"
            },
            "alchemic-vigor": {},
            "implacable-foe": {},
            "dark-gunslinger": {},
            "cascading-corruption": {},
            "doom-approaches": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-spite": {
            "amount": "8.5"
          }
        }
      }

  - name: Quickness Condi Harbinger
    profession: Harbinger
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "39",
          "50",
          "64"
        ],
        "selectedTraits": [
          [
            1883,
            816,
            801
          ],
          [
            875,
            894,
            905
          ],
          [
            2185,
            2220,
            2194
          ]
        ],
        "items": [
          {
            "barbed-precision": {},
            "furious-demise": {},
            "target-the-weak-base": {},
            "target-the-weak": {
              "amount": ""
            },
            "lingering-curse": {},
            "barbed-precision-on-crit": {
              "amount": "2.89"
            }
          },
          {
            "soul-barbs": {
              "amount": ""
            },
            "vital-persistence": {},
            "death-perception": {}
          },
          {
            "wicked-corruption": {
              "amount": ""
            },
            "septic-corruption": {
              "amount": "21.6"
            },
            "alchemic-vigor": {},
            "implacable-foe": {},
            "dark-gunslinger": {},
            "cascading-corruption": {},
            "doom-approaches": {},
            "twisted-medicine": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-spite": {
            "amount": "14.2"
          }
        }
      }

  - name: Quickness Power Harbinger
    profession: Harbinger
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "53",
          "50",
          "64"
        ],
        "selectedTraits": [
          [
            914,
            829,
            853
          ],
          [
            875,
            894,
            893
          ],
          [
            2188,
            2220,
            2194
          ]
        ],
        "items": [
          {
            "spiteful-talisman": {
              "amount": ""
            },
            "awaken-the-pain": {
              "amount": ""
            },
            "close-to-death": {
              "amount": ""
            }
          },
          {
            "soul-barbs": {
              "amount": "97"
            },
            "vital-persistence": {},
            "death-perception": {}
          },
          {
            "wicked-corruption-base": {},
            "wicked-corruption": {
              "amount": "22"
            },
            "septic-corruption": {
              "amount": ""
            },
            "alchemic-vigor": {},
            "implacable-foe": {},
            "twisted-medicine": {},
            "dark-gunslinger": {},
            "cascading-corruption": {},
            "doom-approaches": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "soul-shards": {
            "amount": "3"
          },
          "soul-shards-below": {
            "amount": "3"
          }
        }
      }

  - name: Power Harbinger
    profession: Harbinger
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "53",
          "50",
          "64"
        ],
        "selectedTraits": [
          [
            914,
            829,
            853
          ],
          [
            875,
            894,
            893
          ],
          [
            2188,
            2192,
            2218
          ]
        ],
        "items": [
          {
            "spiteful-talisman": {
              "amount": ""
            },
            "awaken-the-pain": {
              "amount": ""
            },
            "close-to-death": {
              "amount": ""
            }
          },
          {
            "soul-barbs": {
              "amount": "97"
            },
            "vital-persistence": {},
            "death-perception": {}
          },
          {
            "wicked-corruption-base": {},
            "wicked-corruption": {
              "amount": "22"
            },
            "septic-corruption": {
              "amount": ""
            },
            "alchemic-vigor": {},
            "implacable-foe": {},
            "twisted-medicine": {},
            "dark-gunslinger": {},
            "cascading-corruption": {},
            "doom-approaches": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-spite": {
            "amount": "43"
          },
          "soul-shards": {
            "amount": "3"
          },
          "soul-shards-below": {
            "amount": "3"
          }
        }
      }

  - name: Condi Deadeye A/D
    profession: Deadeye
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "44",
          "58"
        ],
        "selectedTraits": [
          [
            1164,
            1292,
            1291
          ],
          [
            1252,
            1190,
            1706
          ],
          [
            2136,
            2160,
            2111
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "preparedness": {},
            "lead-attacks": {
              "amount": ""
            },
            "deadly-ambush": {}
          },
          {
            "silent-scope-base": {},
            "silent-scope-rifle": {},
            "premeditation-base": {},
            "premeditation": {
              "amount": ""
            },
            "iron-sight": {},
            "be-quick-or-be-killed": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Quickness Condi Deadeye Spear
    profession: Deadeye
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "44",
          "58"
        ],
        "selectedTraits": [
          [
            1164,
            1292,
            1291
          ],
          [
            1163,
            1277,
            1706
          ],
          [
            2136,
            2160,
            2146
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "preparedness": {},
            "lead-attacks": {
              "amount": "14"
            },
            "deadly-ambush": {}
          },
          {
            "silent-scope-base": {},
            "silent-scope-rifle": {},
            "premeditation-base": {},
            "premeditation": {
              "amount": ""
            },
            "iron-sight": {},
            "be-quick-or-be-killed": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Staff Daredevil
    profession: Daredevil
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "35",
          "7"
        ],
        "selectedTraits": [
          [
            1245,
            1704,
            1269
          ],
          [
            1268,
            1272,
            1904
          ],
          [
            1949,
            1893,
            2047
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            },
            "revealed-training-revealed": {
              "amount": "6"
            }
          },
          {
            "keen-observer": {},
            "keen-observer-scholar": {},
            "twin-fangs-scholar": {},
            "twin-fangs-flank": {},
            "practiced-tolerance": {},
            "ferocious-strikes": {
              "amount": ""
            },
            "no-quarter": {
              "amount": ""
            }
          },
          {
            "marauders-resilience": {},
            "weakening-strikes": {},
            "staff-master-base": {},
            "staff-master-staff": {},
            "havoc-specialist": {
              "amount": ""
            },
            "bounding-dodger": {
              "amount": "87.5"
            },
            "lotus-training": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "assassins-signet": {
            "amount": "77.1"
          },
          "assassins-signet-active": {
            "amount": "10.3"
          }
        }
      }

  - name: Power Boon Daredevil
    profession: Daredevil
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "44",
          "7"
        ],
        "selectedTraits": [
          [
            1245,
            1704,
            1167
          ],
          [
            1163,
            1190,
            1158
          ],
          [
            1949,
            1893,
            2047
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "preparedness": {},
            "lead-attacks": {
              "amount": ""
            },
            "deadly-ambush": {}
          },
          {
            "marauders-resilience": {},
            "weakening-strikes": {},
            "staff-master-base": {},
            "staff-master-staff": {},
            "havoc-specialist": {
              "amount": ""
            },
            "bounding-dodger": {
              "amount": "100"
            },
            "lotus-training": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "signet-of-agility": {}
        }
      }

  - name: Condi Daredevil
    profession: Daredevil
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "44",
          "7"
        ],
        "selectedTraits": [
          [
            1164,
            1292,
            1291
          ],
          [
            1159,
            1277,
            1706
          ],
          [
            1933,
            1893,
            1833
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "preparedness": {},
            "lead-attacks": {
              "amount": "11.3"
            },
            "deadly-ambush": {}
          },
          {
            "marauders-resilience": {},
            "weakening-strikes": {},
            "staff-master-base": {},
            "staff-master-staff": {},
            "havoc-specialist": {
              "amount": ""
            },
            "bounding-dodger": {},
            "lotus-training": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi Boon Daredevil
    profession: Daredevil
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "44",
          "7"
        ],
        "selectedTraits": [
          [
            1164,
            1292,
            1167
          ],
          [
            1159,
            1277,
            1158
          ],
          [
            1933,
            1893,
            1833
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "preparedness": {},
            "lead-attacks": {
              "amount": ""
            },
            "deadly-ambush": {}
          },
          {
            "marauders-resilience": {},
            "weakening-strikes": {},
            "staff-master-base": {},
            "staff-master-staff": {},
            "havoc-specialist": {
              "amount": ""
            },
            "bounding-dodger": {},
            "lotus-training": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Power Deadeye Rifle
    profession: Deadeye
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "35",
          "58"
        ],
        "selectedTraits": [
          [
            1245,
            1704,
            1269
          ],
          [
            1268,
            1272,
            1904
          ],
          [
            2145,
            2160,
            2111
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "revealed-training-revealed": {
              "amount": "73.8"
            },
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            },
            "dagger-training-dagger": {
              "amount": "50"
            }
          },
          {
            "keen-observer": {},
            "keen-observer-scholar": {},
            "twin-fangs-scholar": {},
            "twin-fangs-flank": {},
            "practiced-tolerance": {},
            "ferocious-strikes": {
              "amount": ""
            },
            "no-quarter": {
              "amount": ""
            }
          },
          {
            "silent-scope-base": {},
            "silent-scope-rifle": {},
            "premeditation-base": {},
            "premeditation": {
              "amount": ""
            },
            "iron-sight": {},
            "be-quick-or-be-killed": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "assassins-signet": {
            "amount": "58.8"
          },
          "assassins-signet-active": {
            "amount": "16.4"
          }
        }
      }

  - name: Power Deadeye Spear
    profession: Deadeye
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "35",
          "58"
        ],
        "selectedTraits": [
          [
            1245,
            1704,
            1269
          ],
          [
            1268,
            1272,
            1904
          ],
          [
            2136,
            2160,
            2093
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "revealed-training-revealed": {
              "amount": "53"
            },
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "keen-observer": {},
            "keen-observer-scholar": {},
            "twin-fangs-scholar": {},
            "twin-fangs-flank": {},
            "practiced-tolerance": {},
            "ferocious-strikes": {
              "amount": ""
            },
            "no-quarter": {
              "amount": ""
            }
          },
          {
            "silent-scope-base": {},
            "silent-scope-rifle": {},
            "premeditation-base": {},
            "premeditation": {
              "amount": ""
            },
            "iron-sight": {},
            "be-quick-or-be-killed": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "assassins-signet": {
            "amount": ""
          },
          "vampiric-slash": {
            "amount": "4.23"
          }
        }
      }

  - name: Quickness Power Deadeye Spear
    profession: Deadeye
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "35",
          "58"
        ],
        "selectedTraits": [
          [
            1245,
            1704,
            1269
          ],
          [
            1268,
            1272,
            1904
          ],
          [
            2136,
            2160,
            2146
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "revealed-training-revealed": {
              "amount": "61"
            },
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "keen-observer": {},
            "keen-observer-scholar": {},
            "twin-fangs-scholar": {},
            "twin-fangs-flank": {},
            "practiced-tolerance": {},
            "ferocious-strikes": {
              "amount": ""
            },
            "no-quarter": {
              "amount": ""
            }
          },
          {
            "silent-scope-base": {},
            "silent-scope-rifle": {},
            "premeditation-base": {},
            "premeditation": {
              "amount": ""
            },
            "iron-sight": {},
            "be-quick-or-be-killed": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "assassins-signet": {
            "amount": ""
          },
          "vampiric-slash": {
            "amount": "3.82"
          }
        }
      }

  - name: Power Deadeye Daggers BQoBK
    profession: Deadeye
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "35",
          "58"
        ],
        "selectedTraits": [
          [
            1245,
            1704,
            1269
          ],
          [
            1268,
            1272,
            1904
          ],
          [
            2145,
            2160,
            2093
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "revealed-training-revealed": {
              "amount": "68"
            },
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            },
            "dagger-training-dagger": {}
          },
          {
            "keen-observer": {},
            "keen-observer-scholar": {},
            "twin-fangs-scholar": {},
            "twin-fangs-flank": {},
            "practiced-tolerance": {},
            "ferocious-strikes": {
              "amount": ""
            },
            "no-quarter": {
              "amount": ""
            }
          },
          {
            "silent-scope-base": {},
            "silent-scope-rifle": {},
            "premeditation-base": {},
            "premeditation": {
              "amount": ""
            },
            "iron-sight": {},
            "be-quick-or-be-killed": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "assassins-signet": {
            "amount": "31"
          },
          "assassins-signet-active": {
            "amount": "24.6"
          }
        }
      }

  - name: Power Deadeye Rifle Silent Scope
    profession: Deadeye
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "35",
          "58"
        ],
        "selectedTraits": [
          [
            1245,
            1704,
            1269
          ],
          [
            1268,
            1272,
            1904
          ],
          [
            2145,
            2118,
            2111
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "revealed-training-revealed": {
              "amount": "75.9"
            },
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "keen-observer": {},
            "keen-observer-scholar": {},
            "twin-fangs-scholar": {},
            "twin-fangs-flank": {},
            "practiced-tolerance": {},
            "ferocious-strikes": {
              "amount": ""
            },
            "no-quarter": {
              "amount": ""
            }
          },
          {
            "silent-scope-base": {},
            "silent-scope-rifle": {},
            "premeditation-base": {},
            "premeditation": {
              "amount": ""
            },
            "iron-sight": {},
            "be-quick-or-be-killed": {}
          }
        ]
      }
    skills:
      {
        "skills": {
          "assassins-signet": {
            "amount": "47.7"
          },
          "assassins-signet-active": {
            "amount": "19.5"
          }
        }
      }

  - name: Condi Specter Spear
    profession: Specter
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "44",
          "71"
        ],
        "selectedTraits": [
          [
            1164,
            1292,
            1291
          ],
          [
            1163,
            1277,
            1706
          ],
          [
            2284,
            2288,
            2264
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "preparedness": {},
            "lead-attacks": {
              "amount": "13.9"
            },
            "deadly-ambush": {}
          },
          {
            "second-opinion-base": {},
            "dark-sentry": {},
            "larcenous-torment": {},
            "strength-of-shadows-base": {},
            "strength-of-shadows-condis": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Condi Specter SC/D
    profession: Specter
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "44",
          "71"
        ],
        "selectedTraits": [
          [
            1164,
            1292,
            1291
          ],
          [
            1163,
            1277,
            1187
          ],
          [
            2284,
            2290,
            2264
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "preparedness": {},
            "lead-attacks": {
              "amount": "13.5"
            },
            "deadly-ambush": {}
          },
          {
            "second-opinion-base": {},
            "second-opinion-scepter": {
              "amount": ""
            },
            "dark-sentry": {},
            "larcenous-torment": {},
            "strength-of-shadows-base": {},
            "strength-of-shadows-condis": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Alacrity Specter SC/D
    profession: Specter
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "44",
          "71"
        ],
        "selectedTraits": [
          [
            1164,
            1292,
            1291
          ],
          [
            1163,
            1277,
            1187
          ],
          [
            2275,
            2285,
            2264
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "preparedness": {},
            "lead-attacks": {
              "amount": "13.3"
            },
            "deadly-ambush": {}
          },
          {
            "second-opinion-base": {},
            "second-opinion-scepter": {
              "amount": ""
            },
            "dark-sentry": {},
            "larcenous-torment": {},
            "strength-of-shadows-base": {},
            "strength-of-shadows-condis": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Alacrity Specter D/D
    profession: Specter
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "28",
          "44",
          "71"
        ],
        "selectedTraits": [
          [
            1164,
            1292,
            1291
          ],
          [
            1163,
            1277,
            1706
          ],
          [
            2284,
            2288,
            2289
          ]
        ],
        "items": [
          {
            "dagger-training-base": {},
            "deadly-ambition": {},
            "revealed-training-base": {},
            "exposed-weakness": {
              "amount": ""
            },
            "potent-poison": {},
            "executioner": {
              "amount": ""
            }
          },
          {
            "preparedness": {},
            "lead-attacks": {
              "amount": "10.5"
            },
            "deadly-ambush": {}
          },
          {
            "second-opinion-base": {},
            "dark-sentry": {},
            "larcenous-torment": {},
            "strength-of-shadows-base": {},
            "strength-of-shadows-condis": {
              "amount": ""
            }
          }
        ]
      }
    skills:
      {
        "skills": {}
      }

  - name: Heal Specter
    profession: Specter
    traits:
      {
        "showAll": false,
        "selectedLines": [
          "20",
          "44",
          "71"
        ],
        "selectedTraits": [
          [
            1160,
            1297,
            1134
          ],
          [
            1163,
            1277,
            0
          ],
          [
            0,
            2285,
            2289
          ]
        ],
        "items": [
          {},
          {
            "preparedness": {},
            "lead-attacks": {
              "amount": ""
            },
            "deadly-ambush": {}
          },
          {
            "second-opinion-base": {},
            "second-opinion-scepter": {
              "amount": ""
            },
            "dark-sentry": {},
            "strength-of-shadows-base": {}
          }
        ]
      }
    skills:
      {
        "skills": {}
      }
